import styled from '@emotion/styled'

import { Button } from '@/legacy/components/Button'
import { Container } from '@/legacy/components/Container'
import type { TypographyColorModifier } from '@/legacy/components/Typography'
import { CONFIG } from '@/ui/shared/styles'

import { DESKTOP_SPACING, MOBILE_SPACING } from './constants'

const AnnouncementBarCloseButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'fontColorMode',
})<{
  fontColorMode: TypographyColorModifier
}>`
  padding: 1rem;
  border: 0;

  min-width: 1rem;
  width: 1rem;
  height: 1rem;
  svg {
    width: 1rem;
    height: 1rem;
  }

  background: none;
  color: ${(props) =>
    props.fontColorMode === 'dark'
      ? 'rgb(var(--theme-text-dark-low))'
      : 'rgb(var(--theme-text-light-high))'};

  :hover,
  :active,
  :focus {
    background: none;
    color: ${(props) =>
      props.fontColorMode === 'dark'
        ? 'rgb(var(--theme-text-dark-high))'
        : 'rgb(var(--theme-text-light-solid))'};
    box-shadow: ${(props) =>
      props.fontColorMode === 'dark'
        ? '0 0 0 4px rgba(var(--theme-surface-darkgrey), 0.24)'
        : '0 0 0 4px rgba(var(--theme-snow), 0.24)'};
  }
`

const AnnouncementBarContainer = styled(Container, {
  shouldForwardProp: (propName) => propName !== 'verticalSpacingSize',
})<{
  verticalSpacingSize: 'sm' | 'lg'
  verticalPosition: 'top' | 'bottom'
}>`
  position: fixed;

  ${({ verticalPosition, verticalSpacingSize }) =>
    `${verticalPosition}: ${MOBILE_SPACING[verticalPosition][verticalSpacingSize]}rem;`}

  left: 50%;
  transform: translateX(-50%);

  z-index: 999;

  width: 95%;
  max-width: ${CONFIG.maxWidths.lg};

  border-radius: var(--theme-border-radius);
  border: 1px solid rgba(var(--theme-surface-silver));
  padding: 1rem 1.25rem;

  .announcement-bar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: unset;
    gap: 0.125rem;
    padding: 0;
  }

  ${CONFIG.media.lg} {
    ${({ verticalPosition, verticalSpacingSize }) =>
      `${verticalPosition}: ${DESKTOP_SPACING[verticalPosition][verticalSpacingSize]}rem;`}
  }
`

export const AnnouncementBarStyles = {
  CloseButton: AnnouncementBarCloseButton,
  Container: AnnouncementBarContainer,
}
