import { HEADER_HEIGHT } from '@/legacy/components/Header/Top/Top'

export const SMALL_SIZE = 0.5

export const MOBILE_SPACING = {
  bottom: {
    sm: SMALL_SIZE,
    lg: 8.5,
  },
  top: {
    sm: SMALL_SIZE + HEADER_HEIGHT,
    lg: 8.5 + HEADER_HEIGHT,
  },
} as const

export const DESKTOP_SPACING = {
  bottom: {
    sm: SMALL_SIZE,
    lg: 7.75,
  },
  top: {
    sm: SMALL_SIZE + HEADER_HEIGHT,
    lg: 7.75 + HEADER_HEIGHT,
  },
} as const
