import type { PropsWithChildren } from 'react'

import type {
  BackgroundColor,
  VerticalPosition,
  VerticalSpacing,
} from '@/legacy/components/Container'
import { Icon } from '@/legacy/components/Icon'

import type { TypographyColorModifier } from '@/legacy/components/Typography'

import { AnnouncementBarStyles } from '../styles'

export type AnnouncementBarRootProps = PropsWithChildren<{
  background?: BackgroundColor
  backgroundColor?: string
  colorModifier?: TypographyColorModifier
  verticalPosition?: VerticalPosition
  bottomSpacing?: VerticalSpacing
  handleClose: () => void
}>

export type AnnouncementBarRootChildrenProps = Omit<
  AnnouncementBarRootProps,
  'handleClose' | 'children'
>

export default function AnnouncementBarRoot({
  children,
  backgroundColor,
  background = 'primary',
  colorModifier = 'light',
  verticalPosition = 'bottom',
  bottomSpacing = 'sm',
  handleClose,
}: AnnouncementBarRootProps) {
  return (
    <AnnouncementBarStyles.Container
      contentClassName="announcement-bar-content"
      backgroundColor={background}
      backgroundColorHex={backgroundColor}
      verticalPosition={verticalPosition}
      verticalSpacingSize={bottomSpacing}
    >
      {children}
      <AnnouncementBarStyles.CloseButton
        onClick={handleClose}
        fontColorMode={colorModifier}
        aria-label="Fechar aviso"
        modifier="solid-invert"
      >
        <Icon icon="close" width={20} height={20} />
      </AnnouncementBarStyles.CloseButton>
    </AnnouncementBarStyles.Container>
  )
}
