import {
  PersistentAnnouncementBar,
  type PersistentAnnouncementBarProps,
} from '@/legacy/components/AnnouncementBar'

const PersistentAnnouncementBarBlock = (props: PersistentAnnouncementBarProps) => (
  <PersistentAnnouncementBar {...props} />
)

export default PersistentAnnouncementBarBlock
