import { useState } from 'react'

import { builderLightText } from '@/legacy/blocks/utils'
import { Typography } from '@/legacy/components/Typography'

import { AnnouncementBar, type AnnouncementBarRootChildrenProps } from '../common/components'

export interface SimpleAnnouncementBarProps extends AnnouncementBarRootChildrenProps {
  message: string
}

const SimpleAnnouncementBar = ({
  message,
  colorModifier = 'light',
  ...rootProps
}: SimpleAnnouncementBarProps) => {
  const [hasBeenClosed, setHasBeenClosed] = useState(false)
  const lightText = builderLightText(colorModifier)

  const handleClose = () => {
    setHasBeenClosed(true)
  }

  if (hasBeenClosed) return null

  return (
    <AnnouncementBar.Root {...rootProps} handleClose={handleClose} colorModifier={colorModifier}>
      <Typography tag="div" dark="medium" html={message} light={lightText} />
    </AnnouncementBar.Root>
  )
}

export default SimpleAnnouncementBar
