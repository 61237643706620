import { useRouter } from 'next/router'

import { CookieStorage } from '@/legacy/core/utils/cookies'

export type Cookie = Record<'name' | 'value', string> & { isDefault?: boolean }

export function usePersistentState({ cookies }: { cookies: Cookie[] }) {
  const { asPath } = useRouter()

  const checkHasCookies = () =>
    cookies.some(({ name, value }) => {
      const cookie = CookieStorage.get(name)
      return !!cookie && cookie === value
    })

  const createPersistentState = () => {
    const defaultCookie = cookies.find(({ isDefault }) => !!isDefault)
    if (!defaultCookie) return
    const { name, value } = defaultCookie
    const expireDate = new Date()
    expireDate.setMonth(expireDate.getMonth() + 11)
    CookieStorage.set(name, value, expireDate.toUTCString(), asPath)
  }

  const disablePersistentState = () =>
    cookies.forEach(({ name }) => {
      CookieStorage.delete(name)
    })

  return { state: checkHasCookies(), createPersistentState, disablePersistentState }
}
