import { useState } from 'react'
import { useEffectOnce } from 'react-use'

import { builderLightText } from '@/legacy/blocks/utils'
import { Typography } from '@/legacy/components/Typography'
import { usePersistentState, type Cookie } from '@/legacy/hooks/usePersistentState'

import { AnnouncementBar, type AnnouncementBarRootChildrenProps } from '../common/components'

export interface PersistentAnnouncementBarProps extends AnnouncementBarRootChildrenProps {
  message: string
  persistentState: {
    allowPersistentState: boolean
    cookies: Cookie[]
  }
}

const PersistentAnnouncementBar = ({
  message,
  persistentState: persistentStateConfig,
  colorModifier = 'light',
  ...rootProps
}: PersistentAnnouncementBarProps) => {
  const { state, createPersistentState } = usePersistentState({
    cookies: persistentStateConfig.cookies,
  })

  const [hasBeenClosed, setHasBeenClosed] = useState(true)
  const lightText = builderLightText(colorModifier)

  const handleClose = () => {
    setHasBeenClosed(true)
    createPersistentState()
  }

  useEffectOnce(() => {
    const shouldCloseAnnouncementBar = state
    if (persistentStateConfig.allowPersistentState && shouldCloseAnnouncementBar) return
    if (!persistentStateConfig.allowPersistentState || !shouldCloseAnnouncementBar)
      setHasBeenClosed(false)
  })

  if (hasBeenClosed) return null

  return (
    <AnnouncementBar.Root {...rootProps} colorModifier={colorModifier} handleClose={handleClose}>
      <Typography tag="div" dark="medium" html={message} light={lightText} />
    </AnnouncementBar.Root>
  )
}

export default PersistentAnnouncementBar
